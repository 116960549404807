.contanerall {
  margin-bottom: 30px;
  margin-top: 30px;
}
.image-contaner {
  width: 300px;
  display: inline-block;
  position: relative;
  margin: 10px;
}

.image-contaner img {
  width: 100%;
  height: 200px;
  border-radius: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.image-text {
  color: white;
  font-size: 23px;
  margin-top: 110px;
}

.seccontaner {
  text-align: center;
}

.mySwiper.swiper {
  width: 100%;
  height: 300px;
}

.swiper-pagination {
  position: relative;
  margin-top: 30px;
}
.swiper-pagination .swiper-pagination-bullet {
  background-color: rgb(6, 81, 90);
}

.why-us .swiper-pagination .swiper-pagination-bullet-active {
  background-color: rgb(6, 81, 90);
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 100%;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 300px;
  height: 300px;
  margin-right: 20px;
  object-fit: contain;
}
@media (max-width: 640px) {
  .swiper-slide img {
    height: 281px;
    object-fit: cover;
  }
  .swiper-pagination .swiper-pagination-bullet {
    background-color: rgb(255, 255, 255);
  }
  .image-contaner {
    margin: auto;
  }
}
