.page-investment {
  background-color: #f7f7f7;
  position: relative;
}
.page_container {
  max-width: 1320px;
  margin: auto;
}
.Header {
  height: 400px;
  background-color: #fff;
}
.Header img {
  height: 100%;
  object-fit: contain;
}
.Header h1 {
  width: 46% !important;
  font-size: 60px !important;
  border-right: 4px solid #00545f;
  height: 57%;
  margin: auto;
  padding-right: 30px;
  display: inline-block;
  color: #00545f;
}
.content {
  padding: 2%;
  margin-top: 100px;
  justify-content: space-between;
}
.content .content_ul div {
  justify-content: space-around;
}
.content .content_ul .BenefitCard {
  width: 48%;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  height: 120px;
  border-radius: 10px;
  align-items: baseline;
}
.content_ul .BenefitCard img {
  width: 64px;
  height: 64px;
  padding: 5px;
}
.BenefitCard p {
  font-size: 15px !important;
  text-align: center;
  color: #212529;
  margin: 0;
  padding: 10px;
  line-height: 20px !important;
}
.content_txt img {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  margin-top: 5px;
}

.content_ul h4 img {
  width: 31px;
  height: 20px;
  transform: rotate(180deg);
  margin-left: 21px;
}
.invest_types {
  background-image: url("../../assets/img/citizinship/783204_11443-NNB1F0.webp");
  background-position: -4px;
  background-size: cover;
  position: relative;
  height: 350px;
  border-radius: 0;
}
.invest_types .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00545fbd;
  border-radius: 0;
}
.invest_types .invest_icons {
  height: 100%;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  z-index: 100;
  position: relative;
  color: #fff;
}
.invest_types .invest_icons img {
  width: 150px;
  height: 150px;
  border: 5px solid #ffffff52;
  padding: 33px;
  border-radius: 31%;
}
.invest_types .invest_icons p {
  font-size: 20px;
}
.content p {
  font-size: 20px;
  line-height: 35px;
}
.content ul li {
  margin: 10px;
}
.content ul li span {
  font-size: 18px;
}
.content ul li img {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  margin-top: 5px;
  margin-left: 10px;
}
.contact_form {
  justify-content: space-between;
  margin: 30px auto;
  padding: 20px;
}
.contact_us {
  height: 550px;
  margin: 50px auto;
}
.contact_us h4 {
  margin-bottom: 20px;
}
.contact_form form {
  height: 350px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}
.contact_form form .bu {
  margin-top: 40px;
}

.contact_form form input {
  height: 55px;
}
.contact_form form .send_button {
  width: 40%;
  background-color: #00545f;
  height: 50px;
  border: 0;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-right: 9px;
  margin-top: 10px;
}
.contact_icons {
  margin: 30px auto;
  padding: 20px;
}
.contact_icons button {
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
  display: block;
  border: 0;
  border-radius: 10px;
  justify-content: space-around;
}
.contact_icons button img {
  padding: 10px;
}
.contact_icons button span {
  padding: 20px;
  font-size: 21px;
  margin-right: 10px;
  font-family: "Montserrat" !important;
}
.whatsApp_button {
  background-color: #25d366;
  color: #fff;
}
.Call_button {
  background-color: #ffffff;
  color: #00545f;
}
.page-investment .footer {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: #00545f;
  padding: 10px;
  justify-content: space-around;
  color: #e8af84;
  align-items: baseline;
  direction: ltr;
}
.page-investment .footer div {
  justify-content: space-around;
}
.page-investment .footer img {
  width: 75px;
  height: auto;
}
.page-investment .footer span {
  margin-left: 10px;
  font-size: 15px;
  font-family: "Montserrat" !important;
}
.float_button {
  position: fixed;
  top: 28%;
  right: 20px;
  background-color: #ffffff;
  color: #00545f;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  padding: 10px;
  align-items: center;
  animation: shake 2s infinite;
  border-radius: 50%;
}
.float_button.hidden {
  opacity: 0;
}
.float_button img {
  padding: 5px;
  width: 50px;
  height: 50px;
}
.float_button span {
  font-size: 16px;

  font-family: "Montserrat" !important;
}

@media (max-width: 1200px) {
  .Header {
    height: 310px;
  }
  .Header h1 {
    font-size: 46px !important;
  }
  .contact_icons button span {
    padding: 8px;
  }
  .BenefitCard p {
    font-size: 14px !important;
  }
}
@media (max-width: 640px) {
  .Header {
    height: 275px;
  }

  .Header h1 {
    font-size: 29px !important;
    width: 90% !important;
    margin: auto;
    padding-right: 15px !important;
  }
  .content {
    display: block !important;
    margin-top: 50px;
  }
  .content_txt,
  .content_ul {
    width: 90% !important ;
    margin: auto;
  }
  .content_txt ul {
    padding: 2px;
    padding-right: 18px;
    font-size: 14px;
  }

  .invest_icons {
    display: block !important;
  }
  .invest_icons div {
    width: 90%;
    margin: auto;
    text-align: center;
    height: 30%;
    align-items: center;
    padding-top: 20px;
  }
  .invest_icons div img {
    width: 70px !important;
    height: 70px !important;
    padding: 10px !important;
    display: inline-block;
  }
  .invest_icons div p {
    display: inline-block;
    margin: 5px 20px;
  }
  .contact_us {
    height: 600px;
    flex-direction: column-reverse;
  }
  #time {
    width: 100%;
    padding: 14px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  }
  .contact_us .contact_form,
  .contact_us .contact_icons {
    width: 90% !important;
    margin: auto !important;
  }
  .contact_us .contact_icons {
    display: flex !important;
  }
  .contact_icons button {
    width: 45%;
    padding: 10px;
  }
  .contact_icons button span {
    padding: 0 !important;
    padding-top: 5px !important;
    font-size: 14px;
  }
  .contact_icons button img {
    padding: 5px !important;
  }
  .footer {
    align-items: center !important
    ;
  }
  .footer > div:nth-child(2) {
    display: block !important;
    width: 56% !important;
  }
  .page-investment .footer span {
    font-size: 12px;
  }
  .content ul li {
    margin: 2px !important;
  }
}

@media (max-width: 390px) {
  .Header h1 {
    font-size: 24px !important;
    padding-right: 12px;
  }
  .BenefitCard p {
    font-size: 10px !important;
    line-height: 13px !important;
  }
  .content_ul .BenefitCard img {
    width: 32px;
    height: 32px;
    padding: 1px;
  }
  .form-control {
    padding: 2px !important;
    font-size: 11px;
  }
  .contact_form select {
    font-size: 12px !important;
  }
  .contact_icons button span {
    font-size: 10px;
  }
  .footer > div:nth-child(2) {
    width: 64% !important;
  }
  .content ul li {
    margin: 2px !important;
  }
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
