.posts-list {
  justify-content: start;
}
.post {
  height: 500px;
  background-color: rgb(255, 255, 255);
  margin: 10px;
  width: 47% !important;
  padding: 0 !important;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.post:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
}
.post .post-img {
  height: 50% !important;
}
.post .post-img a {
  display: block;
  width: 100%;
  height: 100%;
}
.post .post-img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

.post .content {
  padding: 20px 10px;
  height: 50%;
  margin: 0 !important;
}
.post .content .title {
  margin: 10px 0 !important;
  height: 20%;
}
.date_more {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
}
.date_more .postDate {
  color: rgba(6, 81, 90, 0.9);
  display: flex;
  align-items: center;
  font-size: 17px;
}

.date_more .read-more {
  background-color: rgba(6, 81, 90, 0.9);
  color: #fff !important;
  border-radius: 30px;
  padding: 5px 30px;
}
.date_more .read-more a {
  color: #fff !important;
}
.post .content .text {
  height: 49%;
  overflow: hidden;
  margin: 15px 0;
  overflow: hidden;
}
.post .content .text p {
  font-size: 14px;
  line-height: 14px;
}
.page-blog .blog .pagination {
  margin: 40px 0;
}

.Search_bar {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin: 20px 1px;
  margin-top: 0;
  border-radius: 10px;
  padding: 20px;
}
.Search_bar .search-wrapper {
  position: relative;
  display: inline-block;
  width: 70%;
  margin-left: 10px;
}

.Search_bar input[type="search"] {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #d4d4d4;
  outline: 0;
  padding-right: 35px;
}

.Search_bar .fa-search {
  position: absolute;
  right: 10px; /* Adjust the position to your liking */
  top: 50%;
  transform: translateY(-50%);
  color: #aaa; /* Color of the search icon */
  pointer-events: none; /* So the icon doesn't block clicks on the input */
}
.Search_bar button {
  padding: 10px 20px;
  color: #fff;
  border: 0;
  outline: 0;
  background-color: rgb(0, 85, 85);
  border-radius: 10px;
}

@media (max-width: 640px) {
  .posts-list {
    display: block !important;
  }
  .post {
    width: 90% !important;
    height: 460px;
    margin: auto;
  }
  .post .post-img {
    height: 100%;
    object-fit: cover;
  }
  .meta-top {
    margin-top: 0 !important;
  }

  .blog .posts-list .meta-top,
  .meta-top ul li span {
    font-size: 14px !important;
  }
  .blog .posts-list .title {
    margin: 0 !important;
    height: auto;
  }
  .post .content {
    height: 60%;
    margin: 10px;
  }
  .post .content .text {
    margin: 10px 0 !important;
  }
  .post .content .text p {
    margin-bottom: 0 !important;
    line-height: 20px !important;
  }
}
