.btn-cat {
  color: #06515a !important;
  border: 1px solid #000 !important;
}

.btn-cat:hover {
  color: #fff !important;
  border: 1px solid #fff !important;
}

.btn-danger {
  color: #b02a37 !important;
  border: 1px solid #b02a37 !important;
}

.btn-danger:hover {
  color: #fff !important;
  border: 1px solid #fff !important;
  background-color: #b02a37 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
