.Blogs-Page {
  padding: 2% 4%;
  direction: rtl;
}

.Blogs {
  display: flex;
  margin-top: 50px;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.Blogs .Blog {
  width: 45%;
  margin: 1.5%;
  height: 150px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}
.Blogs .Blog > div {
  height: 100%;
}
.Blogs .Blog div > article {
  height: 100%;
}

.AddBlogBtn {
  width: 80px;
  padding: 5px;
  background-color: #fff;
  text-align: center;
  float: right;
  border: 2px solid #06515a;
  border-radius: 5px;
  display: block;
}
.update_button {
  height: 50px;
  width: 50px;
  background-color: #fff;
  background-color: #06525a44;
  color: #06525ae3;
  padding: 5px;
  border: 0 !important;
  border-radius: 50%;
}

.Blog-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Blog-card .post-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  height: 100%;
}
.Blog-card .post-content {
  padding: 10px;
}
.Blog-card .meta-top {
  height: fit-content !important;
  margin: 5px 0 !important;
}
.Blog-card .title {
  margin: 10px 0;
  font-size: 14px;
}
.Blog-card .title a {
  color: #06515a !important;
}
.Blog-card .content {
  margin: 10px 0;
  font-size: 14px;
}
.Blog-card .content p {
  font-size: 14px !important;
}
.Blog-card .meta-top ul {
  padding: 0 !important;
}
.Blog-card .meta-top ul li i {
  margin-left: 10px;
}
.Blog-card .read-more {
  display: flex;
  justify-content: end;
  width: 100%;
}
.Blog-card .read-more button {
  margin: 5px;
}

.Blog-card .read-more .delete-button {
  width: 100px;
  padding: 5px;
  background-color: #111827;
  text-align: center;
  color: aliceblue;
  border-radius: 5px;
  display: block;
}

/* update Blog Page*/

.update-Blog {
  padding: 2%;
}
.update-Blog h1 {
  margin: 40px 0;
}
.update-Blog .Blog-boxes {
  display: flex;
  justify-content: space-around;
}

.update-Blog .Blog-boxes .Blog-Ar,
.update-Blog .Blog-boxes .Blog-tr {
  width: 45%;
}

.update-Blog .Blog-boxes h6 {
  text-align: right;
  width: 100%;
}
.update-Blog .Blog-boxes .Blog-Ar label {
  width: 100px;
  float: right;
  margin-bottom: 15px;
}

.update-Blog .Blog-boxes input {
  width: 100%;
  margin-bottom: 50px;
  border: 2px solid #030a4d49;
  border-radius: 5px;
  padding: 10px;
  text-align: right;
}
.update-Blog .Blog-boxes .Blog-tr input,
.update-Blog .Blog-boxes .Blog-tr textarea {
  text-align: left;
}

.update-Blog .Blog-boxes textarea {
  width: 100%;
  height: 300px;
  margin-bottom: 50px;
  border: 2px solid #030a4d49;
  border-radius: 5px;
  padding: 5px;
  text-align: right;
}
.ck-content {
  overflow-y: scroll;
  height: 500px;
}
.lmMaya {
  width: 45%;
  margin: 42px;
  border: 4px solid #d5d5d580;
}
