.Home_Slider {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.mobile_swiper {
  width: 90% !important;
  height: fit-content !important;
}

.mobile_swiper h2 {
  margin-bottom: 20px !important;
}
.Home_Slider .swiper {
  width: 100%;
  height: 100vh;
}
.bg-transparent {
  background: transparent !important;
}
.swiper-slide {
  position: relative;
  text-align: center;
  font-size: 18px;
  background: transparent;
  height: 100%;
  /* Center slide text vertically */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}
.Home_Slider .swiper-slide .Home_text {
  background-color: rgb(0 69 73);
  position: relative;
  height: 100%;
}

.Home_Slider .swiper-slide .Home_text .textt {
  position: absolute;
  top: 40%;
  left: 5%;
  width: 60%;
  text-align: end;
}

.Home_Slider .swiper-slide .Home_text h1 {
  color: #fff;
}

.Home_Slider .swiper-slide .Home_text p {
  font-size: 18x;
  line-height: 34px;
  color: #ffffffc0;
}

.Home_Slider .swiper-slide .Home_img {
  position: relative;
}
.Home_Slider .swiper-slide .Home_img img {
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.Home_Slider .swiper-slide .Home_img .slide_overlay {
  position: absolute;
  top: 0;
  background-color: rgba(0, 88, 88, 0.466);
  left: 0;
  width: 100%;
  height: 100%;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 100px;
  font-size: 22px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  color: #fff;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 1px;
  stroke: #fff;
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}
.swiper-button-next:after,
.swiper-button-prev:after {
  /* font-size: 5px; */
  color: #f0f8ff66 !important;
}
