@media (max-width: 768px) {
  .details .container .row .col-md-6 p {
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .details {
    margin: 0 !important;
    padding: 0 !important;
  }
}
