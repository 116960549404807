.SignInForm {
  width: 60%;
  margin: 100px auto;
  height: 500px;
}
.SignInForm form div {
  padding: 50px;
}
.SignInForm input {
  margin: 0 40px;
  width: 80%;
  border: 0;
  border-bottom: 1px solid #598ad3;
  font-size: 20px;
  color: #423c3c;
  font-family: "Montserrat", sans-serif;
}
.SignInForm .S-button {
  margin: 35px 80px 35px 70px;
  padding: 20px 40px;
  border: 1px solid #fff;
  background-color: #06515a;
  border-radius: 30px;
  color: aliceblue;
  outline: 0;
}
.SignInForm input:focus {
  outline: none; /* Remove outline */
}
@media (max-width: 640px) {
  .SignInForm {
    width: 90% !important;
    margin: 100px auto;
  }
  .SignInForm form div {
    padding: 10px !important;
  }
  .SignInForm input {
    margin: 15px !important;
    width: 100%;
  }
}
