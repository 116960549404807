.page-link {
  margin: auto 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  border: 1px solid #06525a9c;
  background-color: #fff;
  color: #06525a9c;
  font-weight: 600;
  font-size: 24px;
}
.active > .page-link,
.page-link.active {
  color: var(--bs-pagination-active-color);
  background-color: #06525ae3;
  border-color: #06525a9c;
}
