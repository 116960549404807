.D_container {
  max-width: 1200px;
  margin: auto;
  margin-left: 20%;
  margin-top: 12px;
}
.sideNav {
  position: fixed;
  top: 0;
  margin: 10px;
  bottom: 0;
  width: 17%;
  border-radius: 10px;
  max-width: 300px;
}
.sideNav .gaTCvF {
  width: 100%;
  border-radius: 10px;
  background-color: #06515a !important;
}
.AdminNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77px;
  background-color: #06515a !important;
  border-radius: 10px;
}
.AdminNavbar p {
  color: aliceblue;
  margin: 0 auto;
}
@media (min-width: 1800px) {
  .D_container {
    max-width: 1600px;
  }
}
@media (min-width: 1500px) and (max-width: 1800px) {
  .D_container {
    max-width: 1200px;
  }
}
@media (max-width: 1500px) {
  .D_container {
    max-width: 900px;
    margin-left: 25%;
  }
}
