.about {
  margin-top: 20px;
}
.about > div {
  height: 100%;
}
.about_img {
  height: 100%;
}
.about_img img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}
.about_txt {
  padding: 3%;
}
.about_txt h3 {
  margin-top: 80px;
}
.about_txt p {
  font-size: 1rem;
  margin-top: 20px;
  line-height: 36px;
}
.view_icon {
  margin-left: 10px;
  color: rgb(0, 73, 102);
}
.view_txt h3 {
  color: rgb(0, 73, 102);
}
.view_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
@media (max-width: 640px) {
  .about .about_img {
    display: none;
  }

  .about_txt h3 {
    margin-top: 20px;
  }
}
