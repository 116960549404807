/**
* Template Name: Nova
* Updated: May 30 2023 with Bootstrap v5.3.0
* Template URL: https://bootstrapmade.com/nova-bootstrap-business-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Set main reusable colors and fonts using CSS variables
# Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
--------------------------------------------------------------*/
/* Fonts */

:root {
  --font-default: "Almarai", "Montserrat", system-ui, -apple-system, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Almarai", "Montserrat", sans-serif;
  --font-secondary: "Almarai", "Montserrat", sans-serif;
}

/* Colors */
:root {
  --color-default: #2b180d;
  --color-primary: #56b8e6;
  --color-secondary: rgb(6, 81, 90);
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

.text-about-us {
  font-family: "Almarai", sans-serif;
}
html,
body {
  height: 100%;
  margin: 0;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
.body {
  font-family: "Cairo", sans-serif !important;
}
.bodyen {
  --font-default: "Almarai", "Montserrat", system-ui, -apple-system, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Almarai", "Montserrat", sans-serif;
  --font-secondary: "Almarai", "Montserrat", sans-serif;
  font-family: "Almarai", "Montserrat", sans-serif;
  color: var(--color-default);
}

.bodyar {
  --font-default: "Almarai", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Almarai", sans-serif;
  --font-secondary: "Almarai", sans-serif;
  font-family: "Almarai", sans-serif;
  color: var(--color-default);
}

a {
  color: var(--color-primary);
  text-decoration: none !important;
  transition: 0.3s;
}

a:hover {
  color: #82cbed;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}
p,
div,
span,
ul,
li {
  font-family: "Almarai", "Montserrat", sans-serif !important;
}
/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  overflow: hidden;
  padding: 120px 0;
}
@media (max-width: 640px) {
  section {
    overflow: hidden;
    padding: 30px 0;
  }
}

.section-header {
  text-align: center;
  padding-bottom: 10px;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 20px;
  position: relative;
  color: rgb(6 81 90 / 47%);
  text-transform: uppercase;
  margin-bottom: 50px;
}
.toast-success {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: green;
}

.toast-error {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: red;
}

.section-header h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 2px;
  background: rgb(6 81 90 / 47%);

  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.section-header p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 140px 0 60px 0;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumbs2 {
  padding: 0px 0 0px 0;
  min-height: 5vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 54px;
}

.breadcrumbs2:before {
  content: "";
  background-color: rgba(31, 105, 108, 0.32);
  position: absolute;
  inset: 0;
  border-radius: 54px;
  height: 100%;
}

.breadcrumbsTr {
  padding: 100px 0 60px 0;
  min-height: 20vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.breadcrumbsTr:before {
  content: "";
  background-color: rgba(6, 81, 90, 0.7);
  position: absolute;
  inset: 0;
}

.breadcrumbs:before {
  content: "";
  background-color: rgba(6, 81, 90, 0.7);
  position: absolute;
  inset: 0;
}

.breadcrumbs h2 {
  font-size: 37px;
  text-align: center;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-primary);
}

.breadcrumbs ol a {
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

/* .breadcrumbs ol li+li {
  padding-left: 10px;
} */

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  content: "/";
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(86, 184, 230, 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--color-primary);
  border-top-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 1000;
  padding: 24px 0;
}

@media (max-width: 1200px) {
  .header {
    padding: 12px 0;
  }
}

.header.sticked {
  background: rgba(6, 81, 90, 0.9);
  padding: 12px 0;
}

.header .logo img {
  max-height: 55px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 30px;
  font-weight: 700;
  color: rgb(234, 176, 125);
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.test {
  color: #fff;
}

.lol {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #fff;
  margin-top: 10px;
}

.lolbut {
  color: rgb(6, 81, 90);
  border: none;
}

select:focus {
  border: none;
  outline: 0;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .text-col {
    width: 33%;
  }

  .services-cards .card-item .card-bg {
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 30px;
    font-family: var(--font-default);
    font-size: 15px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover > a {
    color: #fff;
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.2s;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--color-secondary);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .span {
    color: #fff;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover > a {
    color: rgb(234, 176, 125);
  }

  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
    direction: rtl;
  }

  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {
  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .services-cards .card-item .card-bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .text-box h1 {
    font-size: 3em;
  }

  .text-col {
    width: "100%";
  }

  .services-cards .card-item .card-bg2 {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px;
  }
  @media (max-width: 640px) {
    .services-cards {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    padding: 100px 0 10px 0;
    margin: 0;
    background: rgba(6, 81, 90, 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }
  .navbar ul li {
    margin-bottom: 20px;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-family: var(--font-default);
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover > a {
    color: #fff;
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: rgba(20, 35, 51, 0.6);
  }

  .mobile-nav-show {
    display: block;
    color: rgba(255, 255, 255, 0.9);
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
  }

  .mobile-nav-hide {
    color: rgba(255, 255, 255, 0.9);
    font-size: 32px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9999;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active::before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(6, 81, 90, 0.5);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 100vh;
  background: url("../../img/pexels-justus-menke-5213528.webp") top center;
  background-size: cover;
  position: relative;
  padding: 120px 0;
  z-index: 3;
}

.hero-about {
  width: 100%;
  background: url("../../img/pexels-justus-menke-5213528.webp") top center;
  background-size: cover;
  position: relative;
  padding: 120px 0;
  z-index: 3;
}

@media (min-width: 1034px) {
  .hero:after {
    position: absolute;
    content: "";
    width: 40%;
    background: rgb(6, 81, 90);
    top: 0;
    bottom: 0;
  }
}

.hero:before {
  position: absolute;
  content: "";
  background: rgba(6, 81, 90, 0.6);
  inset: 0;
}

.hero-about:after {
  position: absolute;
  content: "";
  width: 40%;
  background: rgb(6, 81, 90);
  top: 0;
  bottom: 0;
}

.hero-about:before {
  position: absolute;
  content: "";
  background: rgba(6, 81, 90, 0.6);
  inset: 0;
}

@media (max-width: 1034px) {
  .hero:before {
    background: rgba(6, 81, 90, 0.7);
  }

  .hero-about:before {
    background: rgba(6, 81, 90, 0.7);
  }
}

.hero .container {
  z-index: 1;
}

.hero-about .container {
  z-index: 1;
}

@media (min-width: 1365px) {
  .hero {
    background-attachment: fixed;
  }
}

.hero h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  font-family: var(--font-secondary);
}

.hero-about h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  font-family: var(--font-secondary);
}

.heroen blockquote {
  color: #fff;
  padding-left: 20px;
  font-size: 15px;
  font-family: var(--font-default);
  border-left: 2px solid rgb(234, 176, 125);
  margin: 40px 0;
}

.heroar blockquote {
  color: #fff;
  padding-right: 20px;
  font-size: 15px;
  font-family: var(--font-default);
  border-right: 2px solid var(--color-primary);
  margin: 40px 0;
}

.hero .btn-get-started {
  font-family: var(--font-secondary);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: rgb(234, 176, 125);
}

.hero .btn-get-started:hover {
  background: rgba(86, 184, 230, 0.8);
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
}

.hero .btn-watch-video i {
  color: rgb(234, 176, 125);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover {
  color: #fff;
}

.hero .btn-watch-video:hover i {
  color: #82cbed;
}

@media (max-width: 640px) {
  .hero h2 {
    font-size: 36px;
    line-height: 1;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 13px;
  }
}

/*--------------------------------------------------------------
# Why Choose Us Section
--------------------------------------------------------------*/
.why-us {
  padding-bottom: 0;
}

.why-us .img-bg {
  min-height: 500px;
  background-size: cover;
}

.why-us .slides {
  background-color: #f7f9fc;
}

.why-us h3 {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: var(--color-secondary);
}

.why-us h4 {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #29486a;
  font-family: var(--font-secondary);
}

.why-us .swiper {
  margin: 140px 140px 120px 140px;
  overflow: hidden;
}

.why-us .swiper-button-prev:after,
.why-us .swiper-button-next:after {
  font-size: 24px;
  color: rgb(6, 81, 90);
}

.why-us .swiper-button-prev {
  left: 80px;
}

.why-us .swiper-button-next {
  right: 80px;
}

.why-us .swiper-pagination {
  margin-top: 30px;
  position: relative;
}

.why-us .swiper-pagination .swiper-pagination-bullet {
  background-color: var(--color-secondary);
}

.why-us .swiper-pagination .swiper-pagination-bullet-active {
  background-color: rgb(6, 81, 90);
}

@media (max-width: 1200px) {
  .why-us .swiper {
    margin: 60px 60px 40px 60px;
  }

  .why-us .swiper-button-prev,
  .why-us .swiper-button-next {
    display: none;
  }
}

@media (max-width: 575px) {
  .why-us .swiper {
    margin: 0px 40px;
  }
}

.page-about .why-us {
  padding: 0 0 80px 0;
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
}

.about .content p {
  margin: 30px 0;
  color: #29486a;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 0 0 15px 26px;
  position: relative;
  font-size: 15px;
  font-weight: 600;
}

.about .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Services List Section
--------------------------------------------------------------*/
.services-list .service-item {
  position: relative;
}

.services-list .service-item .icon i {
  font-size: 32px;
  line-height: 0;
  margin-right: 20px;
  color: #38618e;
}

.services-list .service-item .title {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
}

.services-list .service-item .title a {
  color: rgb(6, 81, 90);
  font-weight: 500;
}

.services-margin-ar .service-item .title {
  margin-right: 10px;
}

.services-list .service-item .title a:hover {
  color: rgb(234, 176, 125);
}

.services-list .service-item .description {
  line-height: 24px;
  font-size: 14px;
  margin-right: 10px;
}

/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action {
  background: linear-gradient(rgba(6, 81, 90, 0.8), rgba(6, 81, 90, 0.8)),
    url("../../img/cta-bg.webp") center center;
  background-size: cover;
  padding: 100px 0;
}

@media (min-width: 1365px) {
  .call-to-action {
    background-attachment: fixed;
  }
}

.call-to-action h3 {
  color: #fff;
  font-size: 28px;
  margin-bottom: 25px;
  font-weight: 700;
}

.call-to-action p {
  color: #fff;
  margin-bottom: 25px;
}

.call-to-action .cta-btn {
  font-family: var(--font-default);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: #2aa5df;
}

.call-to-action .cta-btn:hover {
  background: var(--color-primary);
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features {
  padding-bottom: 0;
  margin-bottom: 30px;
}

.features h3 {
  color: var(--color-secondary);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
}

.features h3 {
  color: #06515a !important;
  color: var(--color-secondary) !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  margin-bottom: 30px !important;
}

.features .icon-list i {
  margin-right: 10px;
  font-size: 24px;
  line-height: 1.2;
}

.features .icon-list span {
  font-size: 18px !important;
  color: #29486a !important;
}

.features .phone-wrap {
  position: absolute;
  right: 0;
}

@media (max-width: 768px) {
  .features .phone-wrap {
    position: relative;
  }
  #features {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #services-list {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.features .phone-wrap img {
  width: 340px;
}

.features .phone-wrap2 img {
  width: 400px;
}

@media (max-width: 992px) {
  .features .phone-wrap img {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .features .phone-wrap img {
    width: 100%;
    display: none;
  }
  .features .details {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}

.features .details {
  margin-top: 80px;
  padding: 50px 0;
  background-color: #f7f9fc;
}

.features .details2 {
  margin-top: 80px;
  background-color: #f7f9fc;
}

.features .details h4 {
  color: var(--color-secondary);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}

.features .details p {
  margin-bottom: 20px;
  font-size: 15px;
  margin-bottom: 30px;
  padding-bottom: 50px;
}

.features .details .btn-get-started {
  font-family: var(--font-primary);
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 10px 32px;
  border-radius: 50px;
  transition: 0.5s;
  background-color: var(--color-secondary);
  color: #fff;
}

.features .details .btn-get-started:hover {
  background: rgb(234, 176, 125);
}

/*--------------------------------------------------------------
# Recent Blog Posts Section
--------------------------------------------------------------*/
.recent-posts .post-box {
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent-posts .post-box .post-img {
  overflow: hidden;
  position: relative;
}

.recent-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-posts .post-box .meta {
  margin-top: 15px;
}

.recent-posts .post-box .meta .post-date {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-primary);
}

.recent-posts .post-box .meta .post-author {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-secondary);
}

.recent-posts .post-box .post-title {
  font-size: 18px;
  color: var(--color-secondary);
  font-weight: 700;
  margin: 15px 0 0 0;
  position: relative;
  transition: 0.3s;
}

.recent-posts .post-box p {
  margin: 15px 0 0 0;
  color: rgba(27, 47, 69, 0.7);
}

.recent-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  margin-top: 15px;
}

.recent-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-posts .post-box:hover .post-title {
  color: var(--color-primary);
}

.recent-posts .post-box:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/
.team .team-member {
  overflow: hidden;
  background: #fff;
}

.team .team-member .member-img {
  position: relative;
  overflow: hidden;

  vertical-align: middle;
}

.team .team-member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .team-member .social a {
  transition: color 0.3s;
  color: rgb(6, 81, 90);
  margin: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team .team-member .social a i {
  line-height: 0;
}

/*هي على طلب الاستاذ محمد  */

.team .team-member .social a:hover {
  color: rgb(234, 176, 125);
}

.team .team-member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .team-member .member-info {
  text-align: center;
}

.team .team-member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: var(--color-secondary);
}

.team .team-member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #6c757d;
}

.team .team-member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #6c757d;
}

.team .team-member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Services Cards Section
--------------------------------------------------------------*/
.services-cards {
  background: #f7f9fc;
}

.services-cards .card-item {
  border: 1px solid rgba(27, 47, 69, 0.1);
  background: #fff;
  position: relative;
  border-radius: 0;
  height: 100%;
}

.services-cards .card-item .card-bg {
  width: 100%;
  height: 55px;
  background-repeat: no-repeat;
}

.services-cards .card-item .card-bg2 {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.services-cards .card-item .card-body {
  padding: 30px;
  height: 100%;
}

.services-cards .card-item h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--color-secondary);
}

.services-cards .card-item p {
  color: var(--color-secondary);
  margin: 0;
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  box-shadow: 0px 0 20px rgba(27, 47, 69, 0.1);
  background: #fff;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #e8eff6;
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.testimonials .swiper-slide {
  opacity: 0.3;
}

@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }

  .testimonials .swiper-pagination {
    margin-top: 0;
  }

  .testimonials .testimonial-item {
    margin: 40px 20px;
  }
}

@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 0.3;
  }

  .testimonials .swiper-slide-active {
    opacity: 1;
    transform: scale(1.12);
  }
}

/*--------------------------------------------------------------
# Portfolio Section
--------------------------------------------------------------*/
.portfolio .portfolio-flters {
  padding: 0;
  margin: 0 auto 30px auto;
  list-style: none;
  text-align: center;
}

.portfolio .portfolio-flters li {
  color: var(--color-secondary);
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  margin: 0 10px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-flters li:hover,
.portfolio .portfolio-flters li.filter-active {
  color: rgba(240, 201, 166);
}

.portfolio .portfolio-flters li:first-child {
  margin-left: 0;
}

.portfolio .portfolio-flters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .portfolio .portfolio-flters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

.portfolio .portfolio-item {
  position: relative;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-default);
  padding-right: 50px;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 0;
  padding-right: 50px;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 50px;
  font-size: 24px;
  top: calc(50% - 14px);
  color: rgba(27, 47, 69, 0.7);
  transition: 0.3s;
  line-height: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: var(--color-primary);
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 14px;
  font-size: 28px;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid var(--color-primary);
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(27, 47, 69, 0.1);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d6e2ef;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact {
  background: url("../../img/contact-bg.png") left top no-repeat;
  background-size: contain;
  position: relative;
}

@media (max-width: 640px) {
  .team .team-member .social {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50px;
    opacity: 0;
    transition: ease-in-out 0.3s;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .team .team-member .member-info {
    padding: 20px 20px 0 5px;
    text-align: center !important;
  }

  .team .team-member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 11px;
    color: var(--color-secondary);
    display: flex;
  }

  .team .team-member .member-info span {
    display: block;
    font-size: 10px;
    font-weight: 400;
    color: #6c757d;
    display: flex;
  }

  .contact {
    background-position: center 50px;
  }

  .ourServes {
    flex-direction: column !important;
  }

  .team .team-member .member-img {
    object-fit: cover;
    width: 150px;
    height: 150px;
    position: relative;
    overflow: hidden;
    display: flex;
    background-size: cover;
    vertical-align: middle;
  }

  .breadcrumbs2 {
    min-height: 5vh;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 54px;
    margin-top: 5px;
  }

  .breadcrumbs2:before {
    content: "";
    background-color: rgba(31, 105, 108, 0.32);
    position: absolute;
    inset: 0;
    border-radius: 54px;
  }
}

.re {
  border-radius: 20px;
}

.contact:before {
  content: "";
  background: rgba(102, 135, 147, 0.7);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.contact .info-item + .info-item {
  margin-top: 40px;
}

.contact .info-item i {
  font-size: 20px;
  background: rgba(6, 81, 90);
  color: #fff;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #1c88ba;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .php-email-form {
  width: 100%;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 12px 15px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #1f98d1;
  border: 0;
  padding: 12px 40px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: var(--color-primary);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog Stylings
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Blog Home Posts List
--------------------------------------------------------------*/

.blog .posts-list article {
  height: 100%;
  border-bottom: 1px solid #d6e2ef;
  padding-bottom: 30px;
}

.blog .posts-list article + article {
  margin-top: 60px;
}

.blog .posts-list .post-img {
  max-height: 240px;
  overflow: hidden;
}

.blog .posts-list .title {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0 0 0;
}

.blog .posts-list .title a {
  color: rgba(6, 81, 90);
  transition: 0.3s;
}

.blog .posts-list .title a:hover {
  color: rgb(234, 176, 125);
}

.blog .posts-list .meta-top {
  margin-top: 20px;
  color: #467ab3;
}

.blog .posts-list .meta-top ul,
.Blog .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
  justify-content: space-between;
}

.blog .posts-list .meta-top ul li + li {
  padding-left: 20px;
}

.blog .posts-list .meta-top i {
  font-size: 16px;
  margin-left: 8px;
  line-height: 0;
  color: rgba(6, 81, 90, 0.8);
}

.blog .posts-list .meta-top a {
  color: rgba(6, 81, 90);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .posts-list .content {
  margin-top: 20px;
}

.blog .posts-list .content p {
  text-align: start;
  line-height: 23px;
  font-size: 17px;
}

.blog .posts-list .read-more a {
  display: inline-block;
  color: #1f98d1;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 500;
}

.blog .posts-list .read-more a:hover {
  color: var(--color-primary);
}

/*--------------------------------------------------------------
# Blog Details Page
--------------------------------------------------------------*/
.blog .blog-details {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.blog .blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .blog-details .title {
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
  color: var(--color-secondary);
}

.blog .blog-details .content {
  margin-top: 20px;
}

.blog .blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .blog-details .content blockquote {
  overflow: hidden;
  background-color: rgba(27, 47, 69, 0.06);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .blog-details .content blockquote p {
  color: var(--color-default);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--color-secondary);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .blog-details .meta-top {
  margin-top: 20px;
  color: var(--color-gray);
}

.blog .blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .blog-details .meta-top ul li + li {
  padding-left: 20px;
}

.blog .blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: rgba(86, 184, 230, 0.8);
}

.blog .blog-details .meta-top a {
  color: var(--color-gray);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid rgba(27, 47, 69, 0.15);
}

.blog .blog-details .meta-bottom i {
  color: #38618e;
  display: inline;
}

.blog .blog-details .meta-bottom a {
  color: rgba(27, 47, 69, 0.8);
  transition: 0.3s;
}

.blog .blog-details .meta-bottom a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags li + li::before {
  padding-right: 6px;
  color: var(--color-default);
  content: ",";
}

.blog .blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog .blog-details .meta-bottom .share i {
  padding-left: 5px;
}

.blog .post-author {
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .post-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog .post-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: var(--color-secondary);
}

.blog .post-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .post-author .social-links a {
  color: rgba(27, 47, 69, 0.5);
  margin-right: 5px;
}

.blog .post-author p {
  font-style: italic;
  color: rgba(var(--color-gray-rgb), 0.8);
  margin-bottom: 0;
}
.Blog {
  direction: rtl;
}
.Blog .meta-top {
  direction: rtl;
  margin: 20px 0;
}
.Blog .meta-top ul {
  width: 90%;
  color: #06515a;
}

.Blog .meta-top ul li i {
  margin-left: 10px;
}

@media screen and (max-width: 1000px) {
  .Blog {
    display: inline !important;
  }
}
/*--------------------------------------------------------------
# Blog Sidebar
--------------------------------------------------------------*/
.blog .sidebar .sidebar-title {
  font-size: 22px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: var(--color-secondary);
}

.blog .sidebar .sidebar-item + .sidebar-item {
  margin-top: 40px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid rgba(27, 47, 69, 0.2);
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form input[type="text"]:focus {
  outline: none;
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: var(--color-primary);
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: rgba(86, 184, 230, 0.8);
}
.user_Blogs {
  justify-content: space-between;
  margin-top: 60px;
}
.user_Blogs .categories {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  margin-left: 10px;
  height: fit-content;
  padding: 20px 10px;
  text-align: end;
}
.user_Blogs .categories ul {
  list-style: none;
  padding: 0;
}

.user_Blogs .categories ul li {
  padding-top: 10px;
  text-decoration: none;
  cursor: pointer;
}

.user_Blogs .categories ul a {
  text-decoration: none;
  font-size: 15px;
  color: #000;
}

.blog .sidebar .categories ul li:hover {
  color: var(--color-default);
}

.blog .sidebar .categories ul li span {
  padding-left: 5px;
  color: rgba(var(--color-default-rgb), 0.4);
  font-size: 14px;
  cursor: pointer;
}

.blog .sidebar .recent-posts .post-item {
  display: flex;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.08);
  padding: 20px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  margin-right: 15px;
}

.blog .sidebar .recent-posts h4 {
  font-size: 18px;
  font-weight: 400;
}

.blog .sidebar .recent-posts h4 a {
  color: var(--color-secondary);
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: var(--color-primary);
}

.blog .sidebar .recent-posts time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: rgba(var(--color-default-rgb), 0.4);
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #38618e;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid rgba(27, 47, 69, 0.15);
  display: inline-block;
  border-radius: 50px;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: rgba(27, 47, 69, 0.8);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Blog Comments
--------------------------------------------------------------*/
.blog .comments {
  margin-top: 30px;
}

.blog .comments .comments-count {
  font-weight: bold;
}

.blog .comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .comments .comment .comment-img {
  margin-right: 14px;
}

.blog .comments .comment .comment-img img {
  width: 60px;
}

.blog .comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .comments .comment h5 a {
  font-weight: bold;
  color: var(--color-default);
  transition: 0.3s;
}

.blog .comments .comment h5 a:hover {
  color: var(--color-primary);
}

.blog .comments .comment h5 .reply {
  padding-left: 10px;
  color: var(--color-secondary);
}

.blog .comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .comments .comment time {
  display: block;
  font-size: 14px;
  color: rgba(27, 47, 69, 0.8);
  margin-bottom: 5px;
}

.blog .comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .comments .reply-form p {
  font-size: 14px;
}

.blog .comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form input:focus {
  box-shadow: none;
  border-color: rgba(86, 184, 230, 0.8);
}

.blog .comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: rgba(86, 184, 230, 0.8);
}

.blog .comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--color-secondary);
}

.blog .comments .reply-form .btn-primary:hover {
  background-color: rgba(27, 47, 69, 0.8);
}

/*--------------------------------------------------------------
# Blog Home Pagination
--------------------------------------------------------------*/
.blog .blog-pagination {
  margin-top: 30px;
  color: #38618e;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #2aa5df;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #dbf0fa;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  background: var(--color-primary);
  color: #fff;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 14px;
  font-family: "Quicksand", sans-serif !important;
}
.footer li span {
  font-family: "Quicksand", sans-serif !important;
}

.footer .footer-content {
  background-color: var(--color-secondary);
  background-size: contain;
  padding: 30px 25px 30px 25px;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info .logo {
  line-height: 0;
  margin-bottom: 25px;
}

.footer .footer-content .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-content .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: rgb(234, 176, 125);
  font-family: var(--font-secondary);
  margin-top: 3px;
}

.footer .footer-content .footer-info p {
  font-size: 15px;
  margin-bottom: 0;
  font-family: var(--font-primary);
  color: #fff;
}

.footer .footer-content .social-links a {
  font-size: 14px;
  line-height: 0;
  display: inline-block;
  width: 32px;
  height: 32px;
  color: rgba(0, 123, 255, 0.5);
  margin-right: 10px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(234, 176, 125);
  color: #fff;
  border-radius: 50px;
}

.facebook:hover {
  background-color: #4267b2 !important;
}

.twitter:hover {
  background-color: #1da1f2 !important;
}

.insta:hover {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  ) !important;
}

.linkedin:hover {
  background-color: #0077b5 !important;
}

.whatsapp:hover {
  background-color: #09cc16 !important;
}

.footer .footer-content h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-content .footer-links ul i {
  margin-right: 2px;
  color: rgb(234, 176, 125);
  font-size: 16px;
  line-height: 0;
}

.footer .footer-content .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-content .footer-links ul a:hover {
  color: rgb(234, 176, 125);
}

.footer .footer-content .footer-contact p {
  line-height: 26px;
  color: #fff;
}

.footer .footer-legal {
  background: #f3f4f4;
  padding: 30px 0;
}

.footer .footer-legal .copyright {
  text-align: center;
  color: var(--color-secondary);
}

.footer .footer-legal .credits {
  padding-top: 4px;
  text-align: center;
  font-size: 13px;
  color: var(--color-secondary);
}

/* New Loader*/

.card {
  width: 100vw;
  min-height: 100vh !important;
  background: #243137;
  position: relative;
  display: grid;
  place-content: center;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

#logo-main,
#logo-second {
  height: 100%;
}

#logo-main {
  fill: #bd9f67;
}

#logo-second {
  padding-bottom: 10px;
  fill: none;
  stroke: #bd9f67;
  stroke-width: 1px;
}

.border {
  position: absolute;
  inset: 0px;
  border: 2px solid #bd9f67;
  opacity: 0;
  transform: rotate(10deg);
  transition: all 0.5s ease-in-out;
}

.bottom-text {
  position: absolute;
  left: 50%;
  bottom: 13px;
  transform: translateX(-50%);
  font-size: 6px;
  text-transform: uppercase;
  padding: 0px 5px 0px 8px;
  color: #bd9f67;
  background: #243137;
  opacity: 0;
  letter-spacing: 7px;
  transition: all 0.5s ease-in-out;
}

.content {
  transition: all 0.5s ease-in-out;
}

.content .logo {
  height: 35px;
  position: relative;
  width: 33px;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.content .logo .logo1 {
  height: 33px;
  position: absolute;
  left: 0;
}

.content .logo .logo2 {
  height: 33px;
  position: absolute;
  left: 33px;
}

.content .logo .trail {
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.content .logo-bottom-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
  color: #bd9f67;
  padding-left: 8px;
  font-size: 11px;
  opacity: 0;
  letter-spacing: none;
  transition: all 0.5s ease-in-out 0.5s;
}

.card:hover {
  border-radius: 0;
  transform: scale(1.1);
}

.card:hover .logo {
  width: 134px;
  animation: opacity 1s ease-in-out;
}

.card:hover .border {
  inset: 15px;
  opacity: 1;
  transform: rotate(0);
}

.card:hover .bottom-text {
  letter-spacing: 3px;
  opacity: 1;
  transform: translateX(-50%);
}

.card:hover .content .logo-bottom-text {
  opacity: 1;
  letter-spacing: 9.5px;
}

.card:hover .trail {
  animation: trail 1s ease-in-out;
}

@keyframes opacity {
  0% {
    border-right: 1px solid transparent;
  }

  10% {
    border-right: 1px solid #bd9f67;
  }

  80% {
    border-right: 1px solid #bd9f67;
  }

  100% {
    border-right: 1px solid transparent;
  }
}

@keyframes trail {
  0% {
    background: linear-gradient(
      90deg,
      rgba(189, 159, 103, 0) 90%,
      rgb(189, 159, 103) 100%
    );
    opacity: 0;
  }

  30% {
    background: linear-gradient(
      90deg,
      rgba(189, 159, 103, 0) 70%,
      rgb(189, 159, 103) 100%
    );
    opacity: 1;
  }

  70% {
    background: linear-gradient(
      90deg,
      rgba(189, 159, 103, 0) 70%,
      rgb(189, 159, 103) 100%
    );
    opacity: 1;
  }

  95% {
    background: linear-gradient(
      90deg,
      rgba(189, 159, 103, 0) 90%,
      rgb(189, 159, 103) 100%
    );
    opacity: 0;
  }
}

/*About US*/

.about-us {
  position: relative;
  border-radius: 54px;
  width: 100%;

  justify-content: center;
  align-items: center;

  display: flex;
}

.about-us-item {
  width: 30% !important;
  object-fit: cover;

  justify-content: center;
  align-items: center;

  display: flex;
}

.about-us-img {
  width: 100%;
  object-fit: cover;
  border-radius: 54px;

  justify-content: center;
  align-items: center;
  height: 100%;

  display: flex;
}

.about-us-text {
  margin-bottom: 25px;
  margin-top: 50px;
}

.tc {
  margin-top: 200px;
}

.tc-h1 {
  margin: 0 100px 0 0;
}

.tc-cart {
  padding: 10px 0 0px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin-top: 50px;
  margin-right: 100px;
  text-align: center;
  width: 25%;
  max-height: 100%;
}

.tc-up {
  margin: 0px 90px 25px 0;
}

.aboutus-image {
  margin-top: 150px;
}

.about-lol {
  margin-right: 100px;
}

.about-footer {
  margin-bottom: 100px;
}

@media (max-width: 640px) {
  .footer .footer-content .footer-info {
    margin-bottom: 30px !important;
  }
  .why-us .img-bg {
    min-height: 500px;
    background-size: cover;
    display: none;
  }
  .aboutus-image {
    margin-top: 15px;
  }

  .about-footer {
    margin-bottom: 50px;
  }

  .about-lol {
    margin-right: 15px;
  }

  .social-links {
    display: none !important;
  }

  .tc-cart {
    padding: 10px 0 0px 0;
    min-height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-top: 20px;
    margin-right: 5px;
    text-align: center;
    width: 48%;
  }

  .tc-up {
    margin: 0px 0px 0px 0px;
  }

  .tc-how-can-teak {
    color: #124345;
    margin: 50px 10px 10px 10px !important;
  }

  .tc-how-can-teak-p {
    margin: 20px 10px 0px 10px !important;
    font-size: 18px;
    color: #524e4e;
  }

  .footer-icon-sty {
    float: right !important;
  }

  .tc {
    margin-top: 50px;
  }

  .tc-h1 {
    margin: 0 0 0 0;
    text-align: center;
  }

  .social-link {
    justify-content: center;
    align-items: center;
  }

  .tc-cart-text {
    padding: 40px 0 10px 0 !important;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    text-align: center;
    width: 80%;
    margin: 0 20px;
  }

  .tc-cart:before {
    content: "";
    background-color: rgba(31, 105, 108, 0.32);
    position: absolute;
    inset: 0;
    border-radius: 20px;
  }

  .breadcrumbs2 {
    margin-top: 50px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 54px;
    height: 100%;
    display: none;
  }

  .breadcrumbs2:before {
    content: "";
    background-color: rgba(31, 105, 108, 0.32);
    position: absolute;
    inset: 0;
    border-radius: 54px;
    height: 100%;
  }

  .about-us-item {
    width: 70% !important;
    object-fit: cover;

    justify-content: center;
    align-items: center;

    display: flex;
  }

  .footer .footer-content .social-link a {
    font-size: 14px;
    line-height: 0;
    display: inline-block;
    width: 32px;
    height: 32px;
    color: rgba(0, 123, 255, 0.5);
    margin-right: 10px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(234, 176, 125);
    color: #fff;
    border-radius: 50px;
  }

  .blog .post-author .social-link {
    margin: 0 10px 10px 0;
  }

  .blog .post-author .social-link a {
    color: rgba(27, 47, 69, 0.5);
    margin-right: 5px;
  }
}

@media (min-width: 641px) {
  .social-link {
    display: none !important;
  }

  .footer-mar {
    margin-top: 76px;
  }
  /* .footer-mar {
    margin-right: 220px;
  } */
}

.tc-cart-text {
  padding: 40px 0 10px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  text-align: center;
  width: 80%;
  margin: 0 20px;
}

.footer-icon-sty {
  float: left;
}

.tc-how-can-teak {
  color: #124345;
  margin: 50px 100px 10px 10px;
}

.tc-how-can-teak-p {
  margin: 30px 100px 0px 10px;
  font-size: 18px;
  color: #524e4e;
}

.tc-cart:before {
  content: "";
  background-color: rgba(31, 105, 108, 0.32);
  position: absolute;
  inset: 0;
  border-radius: 20px;
  height: 100%;
}
