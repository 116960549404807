.Blog-header {
  display: flex;
  justify-content: space-between;
  direction: rtl;
  font-size: 15px;
}
.blog-details {
  direction: rtl;
}
.Blog .blog-details .post-img {
  width: 90%;
  margin: 30px auto;
  margin-top: 60px;
  text-align: center;
}
.Blog .blog-details .post-img img {
  object-fit: cover;
  height: auto;
  max-height: 800px;
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 50px;
}

.Blog .blog-details .content {
  width: 90%;
  margin: auto;
  font-weight: 400;
  font-style: normal;
  margin-top: 40px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 20px;
  padding: 52px;
  text-align: start;
}
.Blog .blog-details .swiper {
  padding: 50px 10px;
  width: 90%;
  margin: 0;
}

.Blog .blog-details .swiper-slide img {
  border-radius: 10px;
}
.Blog .blog-details .swiper-button-next,
.Blog .blog-details .swiper-rtl .swiper-button-prev {
  right: 30px;
  left: auto;
}
.Blog .blog-details .swiper-button-prev,
.Blog .blog-details .swiper-rtl .swiper-button-next {
  left: 30px;
  right: auto;
}

@media (max-width: 640px) {
  .Blog-header {
    text-align: start;
    display: block;
  }
  .Blog .blog-details .post-img img {
    width: 100%;
  }
  .Blog .blog-details .content {
    width: 100%;
    margin: auto;
    padding: 20px;
  }
  .Blog .blog-details .content p {
    font-size: 17px;
  }
  .Blog .blog-details .swiper {
    margin: auto !important;
  }
  .BlogImage_slider {
    display: none;
  }
}
